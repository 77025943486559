import React from 'react'
import Helmet from 'react-helmet'
import './search.scss'
import ReactGA from 'react-ga'
import Head from '../components/head/index.js'
import Foot from '../components/foot/index.js'
import { Link } from "gatsby"
import { Icon } from 'react-icons-kit'
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong'
import Event from '../api/event.js'
import EventListItem from '../components/EventListItem/EventListItem.js'
import QueryString from 'query-string'
import SearchSchema from '../components/SearchSchema/SearchSchema.js'
import Layout from "../components/layout/layout.js"


let baseUrl

class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query:
        QueryString.parse(
          typeof window !== 'undefined' && window.location.search
        ).name || '',
      results: [],
    }
    this.handleSearch = this.handleSearch.bind(this)
  }
  searchForEvents(query) {
    if (query.length > 2) {
      Event.searchEvents(query).then(response => {
        this.setState({ results: response.data.data })
      })
    } else {
      this.setState({ results: [] })
    }
  }
  componentDidMount() {
    baseUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }`
    if (this.state.query) {
      this.searchForEvents(this.state.query)
    }
  }

  handleSearch(event) {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        typeof window !== 'undefined' &&
          window.history.replaceState(
            {},
            '',
            `${baseUrl}?name=${this.state.query}`
          )
        this.searchForEvents(this.state.query)
      }
    )
  }
  render() {
    let searchResults
    if (this.state.results) {
      searchResults = this.state.results.map((event, index) => {
        return (
          <div key={index} className="search__result-item">
            <EventListItem event={event} eventId={event.id} />
          </div>
        )
      })
    }
    return (
      <Layout>
       <div className="search">
          <Helmet>
            <title>Frekvens - Søk konserter</title>
            <link rel="canonical" href={`https://frekvensapp.com/search/`} />
          </Helmet>
          <Head />
          <div className="search__container">
            <div className="search__sticky">
              <div className="search__bar">
                <label>
                  <div className="search__box">
                    <Icon icon={iosSearchStrong} size={36} />
                    <input
                      className="search__input"
                      value={this.state.query}
                      type="search"
                      placeholder="Søk konserter"
                      q="name"
                      onChange={this.handleSearch}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="search__results">{searchResults}</div>
          </div>
          <SearchSchema />
          <Foot />
        </div>
      </Layout>
   
    )
  }
}

export default SearchPage
