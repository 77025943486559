import axios from 'axios'

const apiRoot = 'https://api.frekvensapp.com'

function searchEvents(query) {
  return axios({
    method: 'get',
    url: `${apiRoot}/api/v1/event/search/?name=${query}`,
  })
}

const EventApi = {
  searchEvents,
}
export default EventApi
